
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router , Routes, Route} from 'react-router-dom' ;
import App  from './App.js';
import Pays  from './Pays';
import Niveau1  from './Niveau1';
import reportWebVitals from './reportWebVitals';
import React, { Suspense } from "react";
import "./i18n.js";
import Niveau2 from './Niveau2.js';
import Niveau3 from './Niveau3.js';
import He from './Frais';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

         <Router>
        
        <Routes>
        
        <Route path='/' element={<App/>} />
        <Route path='/frais' element={<He/>} />
        
        <Route path='/Pays' element={<Pays/>} />
        <Route path='/Niveau1' element={<Niveau1/>} />
        <Route path='/Niveau2' element={<Niveau2/>} />
        <Route path='/Niveau3' element={<Niveau3/>} />
        
        </Routes>
      </Router>
   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
