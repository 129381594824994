import { Link } from "react-router-dom";

export default function Formation(){

    return(
        <>
    <section id="more-features" className="more-features section">

<div className="container">

  <div className="row justify-content-around gy-4">

    <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1"  data-aos-delay="100">
      <h3>Pourquoi devenir bénéficiaire ? </h3>
      <p>Adhérer à Doctrina Technologia permet de soutenir le développement des compétences en technologie informatique dès l'enfance, et d'inspirer les jeunes à s'impliquer dans ce domaine.</p>

      <div className="row">

        <div className="col-lg-6 icon-box d-flex">
          <i className="bi bi-easel flex-shrink-0"></i>
          <div>
           
            <p>Nous les offrons des opportunités d'apprentissage en technologie informatique.</p>

          </div>
        </div>

        <div className="col-lg-6 icon-box d-flex">
          <i className="bi bi-patch-check flex-shrink-0"></i>
          <div>
            <p>Nous stimulons leur créativité à travers des projets innovants.</p>
          </div>
        </div>

        <div className="col-lg-6 icon-box d-flex">
          <i className="bi bi-brightness-high flex-shrink-0"></i>
          <div>
            <p>Nous les préparons pour un avenir numérique prometteur.</p>
          </div>
        </div>

        <div className="col-lg-6 icon-box d-flex">
          <i className="bi bi-brightness-high flex-shrink-0"></i>
          <div>
      
            <p>Nous créons un environnement d'apprentissage axé sur le développement personnel.</p>
          </div>
        </div>

      </div>

    </div>

    <div className="features-image col-lg-5 order-1 order-lg-2"  data-aos-delay="200">
      <img src="assets/img/features-3.jpg" alt="" />
    </div>

  </div>

</div>

</section>
       
        </>

    );

}

