import Footer from "./Footer";
import Header1 from "./Header1";
import { Link } from "react-router-dom";


export default function  Niveau1(){

    return(
        <>
        <Header1/>
        <Block1/>
        <Footer/>

             
        </>

    );

}

function Block1(){

return (
<>


<section id="service-details" className="service-details section">

<div className="container">

  <div className="row gy-5">

    <div className="col-lg-4"  data-aos-delay="100">

      <div className="service-box">
        <h4></h4>
        <div className="services-list">
        <Link to={"/Niveau1"}><a href="#" className="active"><i className="bi bi-arrow-right-circle"></i><span>NIVEAU I</span></a></Link>
        <Link to={"/Niveau2"}> <a href="#"><i className="bi bi-arrow-right-circle"></i><span>NIVEAU II</span></a></Link>
        <Link to={"/Niveau3"}> <a href="#"><i className="bi bi-arrow-right-circle"></i><span>NIVEAU III</span></a></Link>
    
        </div>
      </div>

      <div class="service-box">
              <h4>Programme</h4>
              <div class="download-catalog">
                <a href="https://drive.google.com/file/d/1p091IDzIVL8FuHy-VMSRw4xLg9U6JLq0/view?usp=drive_link"><i class="bi bi-filetype-pdf"></i><span> PROGRAMME PDF - 10-13 ANS</span></a>
              </div>
            </div>


      <div className="help-box d-flex flex-column justify-content-center align-items-center">
        <i className="bi bi-headset help-icon"></i>
        <h4>Avez-vous des questions ?</h4>
        <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+41 76 765 18 92</span></p>
        <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+237 691291317</span></p>
        <p className="d-flex align-items-center mt-1 mb-0"><i className="bi bi-envelope me-2"></i> <a href="mailto:contact@example.com">infos@doctrinatech.org</a></p>
      </div>

    </div>

    <div className="col-lg-8 ps-lg-5"  data-aos-delay="200">
      <img src="assets/img/Niveau1.jpg" alt="" className="img-fluid services-img" />
      <h3>  NIVEAU I - (10-13 ans).</h3>
      <p>
      Ce programme vise à initier les jeunes apprenants aux nouvelles technologies de l’information, en
particulier à une méthodologie simplifiée de la conception d’applications. Ceci se passe de manière
ludique en cultivant un esprit entrepreneurial. Chaque module inclura des activités engageantes, des
défis créatifs et des projets concrets pour stimuler leur curiosité, renforcer leurs compétences
techniques et nourrir leur passion pour l'innovation.
      </p>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 0 : Découverte ludique des applications.</span></li>
        <p>
        L'objectif de ce module d'apprentissage est de captiver l'intérêt des jeunes pour les applications informatiques. Axé sur l'exploration ludique et la créativité numérique, il vise à stimuler leur curiosité et à encourager une utilisation responsable de la technologie. Il offre une introduction divertissante à un monde d'opportunités tout en développant leurs compétences numériques de manière éducative. En favorisant une compréhension pratique des applications informatiques, ce module aspire à éveiller leur passion pour la technologie et à les préparer à un avenir où la maîtrise des outils numériques sera essentielle.
      </p>
      
      </ul>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 1 : Introduction à la conception et développement d’application.</span></li>
        <p>
        L'objectif de ce module de formation est d'initier les jeunes au vaste univers des applications informatiques. En comprenant la définition des applications, en explorant les différents types existants, et en apprenant les grandes étapes pour créer une application, les jeunes acquerront les bases pour développer leur créativité et leur compréhension du monde numérique qui les entoure. Ce cours vise à leur fournir des outils pour imaginer et développer leurs propres projets, favorisant ainsi une utilisation responsable et éclairée des applications informatiques.
      </p>
      
      </ul>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 2: Principes de Base de programmation.</span></li>
        <p>
        Ce module a pour objectif de fournir aux enfants une introduction aux principes de base de la programmation. À travers des activités pratiques, des jeux interactifs et des discussions engageantes, nous visons à : Tout d'abord, permettre aux enfants de comprendre les concepts fondamentaux tels que les boucles, les conditions, les variables et la séquentialité, en utilisant des exemples concrets et des activités pratiques. Ensuite, Nous encouragerons les enfants à développer leur capacité à penser de manière logique, à résoudre des problèmes et à séquencer des actions, éléments essentiels à la programmation informatique. De plus, Nous stimulerons la créativité des enfants en leur permettant d'expérimenter la création d'instructions séquentielles pour des actions simples. Enfin, Nous susciterons l'intérêt des enfants pour le domaine de la programmation informatique en leur offrant une première expérience amusante, visant à démystifier la programmation et à encourager une exploration future de ce domaine.
      </p>
      
      </ul>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 3: Concevoir des applications qui répondent aux besoins.</span></li>
        <p>
        Ce module vise à fournir aux jeunes les connaissances fondamentales et les compétences pratiques nécessaires pour concevoir et maquetter des applications simples et intuitives. Grâce à des activités pratiques et à une approche ludique, les jeunes apprendront les principes de la conception d'interface utilisateur, utiliseront des outils de conception adaptés aux enfants et développeront leur créativité, leur pensée logique et leurs compétences en communication.
      </p>
      
      </ul>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 4: Développement d’une simple application.</span></li>
        <p>
        Ce module vise à fournir aux jeunes une introduction complète au monde du développement d'applications en leur enseignant les concepts et les compétences fondamentaux des applications Web, natives et hybrides. Grâce à des activités pratiques et à une approche ludique, les jeunes apprendront les langages de programmation et les outils nécessaires pour chaque type d'application. Ils exploreront les avantages et les inconvénients de chaque plateforme, acquerront des connaissances sur les principes de conception d'interface utilisateur et développeront leur pensée logique, leur créativité et leurs compétences en résolution de problèmes.
      </p>
      
      </ul>

    </div>

  </div>

</div>

</section>



</>





)


}