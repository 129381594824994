

import Header from './Header';
import Banner from './Banner';
import Palier from './Palier';
import Nous from './Nous';
import Atelier from './Atelier';
import Adherer from './Adherer';
import Soutenir from './Soutenir';
import Formation from './Formation';
import Questions from './Questions';
import Cible from './Cible';
import Contact from './Contact';
import Footer from './Footer';


// Filename - App.js
 
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
 
// Contains the value and text for the options
const languages = [
    { value: "", text: "Options" },
    { value: "en", text: "English" },
    { value: "fr", text: "French" },
    { value: "de", text: "German" },
];





function App(){

  return (


    <>

    <Header /> 
    <Banner/>
    <Palier/>
    <Nous/>
    <Atelier/>
    <Adherer/>
    <Soutenir/>
    <Formation/>
    <Cible/>
    <Questions/>
    <Contact/>
    <Footer/>

    </>
  );
}

export default App;
