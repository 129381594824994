import { Link } from "react-router-dom";

export default function Footer(){

    return(
        <>
        
        <Block1 />
     
        </>

    );

}

function Block1(){
    return(
<>

<footer id="footer" class="footer position-relative">

<div class="container footer-top">
  <div class="row gy-4">
    <div class="col-lg-4 col-md-6 footer-about">
      <a href="index.html" class="logo d-flex align-items-center">
        <span class="sitename">DOCTRINA-TECH</span>
      </a>
      <div class="footer-contact pt-3">
        <p>Genèvre, Suisse</p>
        <p><strong>Email:</strong> <span>infos@doctrinatech.org</span></p>
      </div>
      <div class="social-links d-flex mt-4">
        <a href=""><i class="bi bi-twitter-x"></i></a>
        <a href=""><i class="bi bi-facebook"></i></a>
        <a href=""><i class="bi bi-instagram"></i></a>
        <a href=""><i class="bi bi-linkedin"></i></a>
      </div>
    </div>

    <div class="col-lg-2 col-md-3 footer-links">
      <h4>Navigations</h4>
      <ul>
        <li><a href="#">Accueil</a></li>
        <li><a href="#">À propos</a></li>
        <li><a href="#">Ateliers</a></li>
        <li><a href="#">Événements </a></li>
        <li><a href="#">Actualité</a></li>
      </ul>
    </div>

    <div class="col-lg-2 col-md-3 footer-links">
      <h4>Actions</h4>
      <ul>
        <li><a href="#">Adhérer</a></li>
        <li><a href="#">Soutenir</a></li>
        <li><a href="#">Nous contactez</a></li>
      </ul>
    </div>


</div>

<div class="container copyright text-center mt-4">
  <p><span></span> <strong class="px-1 sitename">DOCTRINA TECHNOLOGIA</strong><span></span></p>
  <div class="credits">

  </div>
</div>
</div>

</footer>
   
</>
      
    );
}


