import { Link } from "react-router-dom";

export default function Header(){

    return(
        <>
        
        <Block1 />
     
        </>

    );

}

function Block1(){
    return(
<>
<header id="header" className="header d-flex align-items-center fixed-top">
    <div className="container-fluid container-xl position-relative d-flex align-items-center">

      <a href="https://doctrinatech.org" className="logo d-flex align-items-center me-auto">
        <img src="assets/img/logo2.jpg" alt="" />
        <h1 className="sitename">Doctrina-Tech</h1>
      </a>

    </div>
  </header>
   
</>
      
    );
}


