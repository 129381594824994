import { Link } from "react-router-dom";

export default function Questions(){

    return(
        <>
        
        <section id="faq" className="faq section">


<div className="container section-title" >
  <h2>QUESTIONS FRÉQUENTES</h2>
</div>

<div className="container">

  <div className="row justify-content-center">

    <div className="col-lg-10"  >

      <div className="faq-container">

        <div className="faq-item faq-active" >
          <h3>Quels sont les critères d'éligibilité pour adhérer à DOCTRINA TECHNOLOGIA  ?</h3>
          <div className="faq-content">
            <p>Pour adhérer à DOCTRINA TECHNOLOGIA, les apprenants doivent être âgés de 10 à 18 ans ou plus et être intéressés par l'informatique.
            </p>
          </div>
          <i className="faq-toggle bi bi-chevron-right"></i>
        </div>

        <div className="faq-item faq-active">
          <h3>Quelles sont les formations et les ateliers proposés par Doctrina technologia ?</h3>
          <div className="faq-content">
            <p>Trois cycles de formation sont proposés : Initiation & Introduction, Compétences intermédiaires, Compétences avancées. Quant aux ateliers, des exposés, présentations seront faits par des experts sur leur champ de compétences respectifs. Ils seront suivis de séance de questions-réponses.</p>
          </div>
          <i className="faq-toggle bi bi-chevron-right"></i>
        </div>


        <div className="faq-item faq-active">
          <h3>De quoi a besoin un apprenant ? ?</h3>
          <div className="faq-content">
            <p>
            un ordinateur, un stylo, un papier et un verre d'eau.
            </p>
          </div>
          <i className="faq-toggle bi bi-chevron-right"></i>
        </div>

        <div className="faq-item faq-active">
          <h3>Comment puis-je m'inscrire aux événements et Ateliers de Doctrina technologia ?</h3>
          <div className="faq-content">
            <p>Les adhérents ont l’avantage de s’inscrire aux évènements et ateliers en cliquant sur respectivement sur les boutons « Ateliers » ou « Evènements ».
            </p>
          </div>
          <i className="faq-toggle bi bi-chevron-right"></i>
        </div>

        <div className="faq-item faq-active">
          <h3>Quels sont les avantages de devenir membre de Doctrina technologia ?</h3>
          <div className="faq-content">
            <p>En devenant adhérent de DOCTRINA TECHNOLOGIA, vous bénéficiez des formations, évènements et divers ateliers.            .</p>
          </div>
          <i className="faq-toggle bi bi-chevron-right"></i>
        </div>

        <div className="faq-item faq-active">
          <h3>Quels sont les projets futurs de Doctrina technologia ?</h3>
          <div className="faq-content">
            <p>DOCTRINA TECHNOLOGIA envisage à moyen terme former, motiver, inciter les jeunes apprenants de manière ludique aux nouvelles technologies. 
            </p>
          </div>
          <i className="faq-toggle bi bi-chevron-right"></i>
        </div>

        <div className="faq-item faq-active">
          <h3>Comment Doctrina technologia mesure-t-elle l'impact de ses activités ?</h3>
          <div className="faq-content">
            <p>A travers la plateforme doctrinatech.com il est possible de mesurer l’impact des activités au moyen des différentes données recueillies.</p>
          </div>
          <i className="faq-toggle bi bi-chevron-right"></i>
        </div>
        <div className="faq-item faq-active">
          <h3>Quelles sont les opportunités de dons et bénévolat disponibles au sein de Doctrina technologia ?</h3>
          <div className="faq-content">
            <p>Il est possible de faire des dons matériels ou d’apporter un soutien financier à l’association. Des bénévoles dans le cadre de l’organisation respectivement la bonne marche de DOCTRINA TECHNOLOGIA sont les bienvenus.</p>
          </div>
          <i className="faq-toggle bi bi-chevron-right"></i>
        </div>

      </div>

    </div>

  </div>

</div>

</section>

       
        </>

    );

}

