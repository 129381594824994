
import Footer from "./Footer";
import Header from "./Header1";
import Process from "./Process";


export default function Pays(){
  return (
    <>
    <Header/>
    <Process/>
    <Block1/>

</>
)
}

function Block1(){
  return (
 
  <section id="services" className="services section">
    

<div className="container section-title" >
  <h2>Choisir votre situation géographique</h2>
</div>
<div className="container">

  <div className="row g-5">

    <div className="col-lg-6"  data-aos-delay="100">
      <div className="service-item item-cyan position-relative">
        <i className="bi-bg icon"><img src="assets/img/suisse.png" className="img-fluid " alt=""/></i>
        <div>
          <h3>N'importe où dans le monde</h3>
          <p>Si vous êtes en Suisse ou autre part dans le monde nous vous invitons à adhérer ici .</p>
          <a href="http://doctrinatech.com" className="read-more stretched-link">Adhérer<i className="bi bi-arrow-right"></i></a>
        </div>
      </div>
    </div>

    <div className="col-lg-6"  data-aos-delay="200">
      <div className="service-item item-orange position-relative">
        <i className="bi bi- icon"><img src="assets/img/cameroun.png" className="img-fluid " alt=""/></i>
        <div>
          <h3>Cameroun</h3>
          <p>Si vous résidez au Cameroun nous vous invitons à adhérer à Doctrina Technologia Cameroun.</p>
          <a href="http://doctrinatech.com/connexion_cameroun" className="read-more stretched-link">Adhérer<i className="bi bi-arrow-right"></i></a>
        </div>
      </div>
    </div>



  </div>

</div>

</section>

   
  )
}




