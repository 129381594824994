import { Link } from "react-router-dom";

export default function Banner(){

    return(
        <>
        
        <section id="hero" className="hero section">
      <div className="hero-bg">
        <img src="assets/img/hero-bg-light.webp" alt="" />
      </div>
      <div className="container text-center">
        <img src="assets/img/hero-services-img.webp" className="img-fluid hero-img" alt=""/>
        <div className="d-flex flex-column justify-content-center align-items-center">
        
          <h1><span> Doctrina Technologia</span></h1>
          <p>Basée en Suisse, notre mission est d'inspirer, d'éduquer, d’encourager la prochaine génération de leaders passionnés par les nouvelles technologies. En rejoignant notre communauté, les jeunes auront accès à des programmes interactifs, des mentorats personnalisés les préparant ainsi à un avenir prometteur. Rejoignez-nous et cultivons ensemble des esprits audacieux et visionnaires qui façonnerons le monde de demain.</p>
        
        </div>
      </div>

    </section>
       
        </>

    );

}

