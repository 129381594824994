import { Link, NavLink , ScrollRestoration , Outlet } from "react-router-dom";

export default function Cible(){

    return(
        <>
        
        <section id="courses" class="courses section">

<div class="container">
  <div class="row">

    <div class="col-lg-4 col-md-6 d-flex align-items-stretch"  data-aos-delay="100">
      <div class="course-item">
        <img src="assets/img/course-1.jpg" class="img-fluid" alt="" />
        <div class="course-content">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <p class="category">Cycle I - 3 mois</p>
            <p class="price">10-13 ans</p>
          </div>

          <h3>  <Link to={"/Niveau1"}><a href="">Initiation et Introduction</a> </Link> </h3>
          <p class="description">Nous initions les apprenants aux concepts d’application afin de donner un premier aperçu de l'informatique par la suite nous approfondirons ces concepts grâce à des activités ludiques et récréatives.</p>
          <div class="trainer d-flex justify-content-between align-items-center">
        
            <div class="trainer-rank d-flex align-items-center">
            <i class="bi bi-person user-icon"></i>&nbsp;  <Link to={"/Niveau1"}>Plus de détails</Link>
              &nbsp;&nbsp;
              
            </div>
          </div>
        </div>
      </div>
    </div> 

    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"  data-aos-delay="200">
      <div class="course-item">
        <img src="assets/img/course-2.jpg" class="img-fluid" alt="" />
        <div class="course-content">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <p class="category">Cycle II - 3 mois</p>
            <p class="price">14-17 ans</p>
          </div>

          <h3><a href="course-details.html">Compétences Intermediaires</a></h3>
          <p class="description">Nous développons de manière approfondie les compétences des apprenants sur les langages de programmation et outils de développement, ainsi que la capacités à mettre en œuvre des solutions pour répondre à des besoins spécifiques.</p>
          <div class="trainer d-flex justify-content-between align-items-center">
            <div class="trainer-rank d-flex align-items-center">
              <i class="bi bi-person user-icon"></i>&nbsp;<Link to={"/Niveau2"}>Plus de détails</Link>
              &nbsp;&nbsp;
            
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"  data-aos-delay="300">
      <div class="course-item">
        <img src="assets/img/course-3.jpg" class="img-fluid" alt="" />
        <div class="course-content">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <p class="category">Cycle III - 3 mois</p>
            <p class="price">18 ans- plus</p>
          </div>

          <h3><a href="course-details.html">Compétences Avancées</a></h3>
          <p class="description">Nous développons les capacités des apprenants à identifier des opportunités dans le domaine de la technologie, à développer des idées, pouvant déboucher sur la conception des solutions informatiques et leur commercialisation avec succès.</p>
          <div class="trainer d-flex justify-content-between align-items-center">
     
            <div class="trainer-rank d-flex align-items-center">
              <i class="bi bi-person user-icon"></i>&nbsp;<Link to={"/Niveau3"}>Plus de détails</Link>
              &nbsp;&nbsp;
              
            </div>
          </div>
        </div>
      </div>
    </div> 

  </div>

</div>

</section>
       
        </>

    );

}

