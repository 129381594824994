import { Link } from "react-router-dom";

export default function  Contact(){

    return(
        <>
        
        <section id="Contact" className="contact section">

<div className="container section-title" >
  <h2>Contact</h2>

</div>

<div className="container"  data-aos-delay="100">

  <div className="row gy-4">

    <div className="col-lg-6">
      <div className="info-item d-flex flex-column justify-content-center align-items-center"  data-aos-delay="200">
        <i className="bi bi-geo-alt"></i>
         <h3>Association DOCTRINIA TECHNOLOGIA</h3>
        <p>Avenue Cure Baud 51 ,1212 Grand-Lancy</p>
        <p>Suisse</p>
      </div>
    </div>

    <div className="col-lg-3 col-md-6">
      <div className="info-item d-flex flex-column justify-content-center align-items-center"  data-aos-delay="300">
        <i className="bi bi-telephone"></i>
        <h3>Téléphone</h3>
        <p>+41 76 765 18 92</p>
      </div>
    </div>

    <div className="col-lg-3 col-md-6">
      <div className="info-item d-flex flex-column justify-content-center align-items-center"  data-aos-delay="400">
        <i className="bi bi-envelope"></i>
        <h3>Email</h3>
        <p>infos@doctrinatech.org</p>
      </div>
    </div>

  </div>

  <div className="row gy-4 mt-1">
    <div className="col-lg-6"  data-aos-delay="300">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2762.7641679414983!2d6.1146496763130695!3d46.17535007109448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c7b14092c9305%3A0x6d5362d4b376b5b9!2sAv.%20du%20Cur%C3%A9%20Baud%2051%2C%201212%20Lancy%2C%20Switzerland!5e0!3m2!1sen!2scm!4v1721580284313!5m2!1sen!2scm" width="550" height="480"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      
    </div>

    <div className="col-lg-6">
      <form action="forms/contact.php" method="post" className="php-email-form"  data-aos-delay="400">
        <div className="row gy-4">

          <div className="col-md-6">
            <input type="text" name="name" className="form-control" placeholder="Noms" required=""/>
          </div>

          <div className="col-md-6 ">
            <input type="email" className="form-control" name="email" placeholder="Email" required="" />
          </div>

          <div className="col-md-12">
            <input type="text" className="form-control" name="subject" placeholder="Sujet" required="" />
          </div>

          <div className="col-md-12">
            <textarea className="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
          </div>
-
          <div className="col-md-12 text-center">
            <div className="loading">Loading</div>
            <div className="error-message"></div>
            <div className="sent-message">Your message has been sent. Thank you!</div>

            <button type="submit">Envoyer</button>
          </div>

        </div>
      </form>
    </div>

  </div>

</div>

</section>
       
        </>

    );

}

