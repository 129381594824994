import Footer from "./Footer";
import Header1 from "./Header1";
import { Link } from "react-router-dom";

export default function  Niveau3(){

    return(
        <>
        <Header1/>
        <Block1/>
        <Footer/>

             
        </>

    );

}

function Block1(){

return (
<>


<section id="service-details" className="service-details section">

<div className="container">

  <div className="row gy-5">

    <div className="col-lg-4"  data-aos-delay="100">

    <div className="service-box">
        <h4></h4>
        <div className="services-list">
        <Link to={"/Niveau1"}>   <a href="#" ><i className="bi bi-arrow-right-circle"></i><span>NIVEAU I</span></a></Link>
        <Link to={"/Niveau2"}>  <a href="#" ><i className="bi bi-arrow-right-circle"></i><span>NIVEAU II</span></a></Link>
        <Link to={"/Niveau3"}><a href="#" className="active"><i className="bi bi-arrow-right-circle"></i><span>NIVEAU III</span></a></Link>
    
        </div>
      </div>
      <div class="service-box">
              <h4>Programme</h4>
              <div class="download-catalog">
                <a href="https://drive.google.com/file/d/1rIkKIycKSj3QAYBlWz73hLB-0LKTU1Uv/view?usp=sharing"><i class="bi bi-filetype-pdf"></i><span> PROGRAMME PDF - 18 ans- Plus</span></a>
              </div>
            </div>


      <div className="help-box d-flex flex-column justify-content-center align-items-center">
        <i className="bi bi-headset help-icon"></i>
        <h4>Avez-vous des questions ?</h4>
        <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+41 76 765 18 92</span></p>
        <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+237 691291317 </span></p>
        <p className="d-flex align-items-center mt-1 mb-0"><i className="bi bi-envelope me-2"></i> <a href="mailto:contact@example.com">infos@doctrinatech.org</a></p>
      </div>

    </div>

    <div className="col-lg-8 ps-lg-5"  data-aos-delay="200">
      <img src="assets/img/Niveau3.jpg" alt="" className="img-fluid services-img" />
      <h3>NIVEAU III -  Débutant (18 ans - plus).</h3>
      <p>
      L'objectif de cette formation est de doter les jeunes des compétences techniques nécessaires pour développer des applications logicielles en utilisant .NET C# et React, tout en leur enseignant les principes d'analyse fonctionnelle et d'architecture logicielle. En favorisant un esprit d'entreprise, le programme vise à inspirer les participants à envisager des carrières dans l'entrepreneuriat technologique, en développant également leurs compétences en collaboration et communication. Cela leur permettra non seulement de créer des applications innovantes, mais aussi de devenir des acteurs proactifs dans le monde numérique.
      </p>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 1 : Introduction au développement logiciel.</span></li>
        <p>
        Ce module couvre les bases du développement logiciel, en commençant par les principes fondamentaux qui guident ce domaine. Les jeunes apprendront les différentes étapes du cycle de vie du développement logiciel, ce qui leur permettra de comprendre comment un projet évolue depuis l'idée initiale jusqu'à sa réalisation. Ils seront également introduits aux outils et technologies utilisés dans le développement logiciel, ainsi qu'à l'installation des environnements de développement. Enfin, ils auront l'occasion de créer des projets simples pour mettre en pratique leurs nouvelles compétences.
      </p>
      
      </ul>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 2 : .NET C# </span></li>
        <p>
        Ce module introduit les principes de base du langage de programmation C#. Les jeunes commenceront par apprendre à utiliser les variables, les types de données et les opérateurs. Ensuite, ils découvriront les structures de contrôle et les fonctions, qui sont essentielles pour structurer leurs programmes. La programmation orientée objet sera également abordée, ce qui leur permettra de comprendre comment organiser leur code de manière efficace. Ils apprendront à développer des applications Windows avec .NET et auront l'occasion de coder des programmes C# simples, tout en créant des interfaces utilisateur pour leurs applications.
      </p>
      
      </ul>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 3: React .</span></li>
        <p>
        Ce module est une introduction à React, une bibliothèque populaire pour le développement d'interfaces utilisateur. Les jeunes commenceront par apprendre les composants et les accessoires de React, qui sont des éléments clés pour construire des applications. Ils découvriront ensuite comment gérer l'état dans React, ce qui est essentiel pour rendre leurs applications interactives. Le module leur permettra également de développer des interfaces utilisateur attrayantes avec React. Enfin, ils auront l'occasion de créer des applications React de base et d'apprendre à manipuler l'état pour améliorer l'expérience utilisateur.
      </p>
      </ul>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 4: Analyse fonctionnelle.</span></li>
        <p>
        Ce module présente les principes de l'analyse fonctionnelle, qui sont essentiels pour comprendre les besoins d'un projet. Les jeunes apprendront à spécifier les exigences fonctionnelles, c'est-à-dire ce que l'application doit accomplir pour répondre aux attentes des utilisateurs. Ils découvriront également comment créer des diagrammes de cas d'utilisation, qui illustrent les interactions entre les utilisateurs et le système. Enfin, ils auront l'occasion de rédiger des spécifications des exigences fonctionnelles pour une application logicielle simple, leur permettant ainsi de mettre en pratique ce qu'ils ont appris.
      </p>
      
      </ul>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 5:Architecture logicielle.</span></li>
        <p>
        Ce module aborde les principes de l'architecture logicielle, qui sont cruciaux pour créer des systèmes efficaces. Les jeunes découvriront les modèles d'architecture courants, qui leur fourniront une base solide pour comprendre comment structurer une application. Ils apprendront également à concevoir des systèmes qui sont à la fois évolutifs et maintenables, ce qui est essentiel pour s'adapter aux besoins futurs. Enfin, ils auront l'opportunité de concevoir l'architecture d'une application logicielle simple, mettant en pratique leurs connaissances et compétences acquises tout au long du module.
      </p>
      </ul>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 6: Développement d'applications Web.</span></li>
        <p>
        Ce module aborde le développement d'applications Web dans son ensemble. Les jeunes apprendront l'architecture des applications Web, ainsi que le développement côté client avec React et côté serveur avec .NET C#. L'intégration de bases de données sera également couverte, leur permettant de développer des applications Web complètes qui se connectent à des bases de données.
      </p>
      </ul>

      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 7:Entrepreneuriat technologique.</span></li>
        <p>
        Ce module sur les principes de l'entrepreneuriat est essentiel pour les futurs entrepreneurs. Il leur enseigne d'abord à valider leurs idées commerciales, puis à développer des plans d'affaires qui servent de guide stratégique. La compréhension du financement et de la collecte de fonds est également cruciale pour obtenir les ressources nécessaires au lancement de leurs projets. Enfin, l'élaboration et la rédaction de plans d'affaires aident à formaliser leurs visions et à attirer des investisseurs. Ces compétences constituent une base solide pour réussir dans le monde entrepreneurial.
      </p>
      </ul>

      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 8 : Projet final.</span></li>
        <p>
        Les participants travailleront en équipe pour créer une application logicielle complète en utilisant les technologies apprises dans le programme. À la fin du projet, ils présenteront leurs réalisations devant un panel de juges qui leur fournira des retours constructifs. Cela leur permettra d'améliorer leurs compétences et d'affiner leurs projets en fonction des commentaires reçus. C'est une excellente occasion de mettre en pratique leurs connaissances et de recevoir des conseils d'experts !
      </p>
      </ul>

      


    </div>

  </div>

</div>

</section>



</>





)


}