import { Link } from "react-router-dom";

export default function Process(){

    return(
        <>

<section className="work-process">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="pricing__content mb-60 mb-sm-40 mb-xs-30 text-center">
                        <br/>
                        <br/>
                        <h2 className="title color-pd_black">Processus pour devenir bénéficiaire</h2>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="row mb-minus-30 last_no_bullet">
                <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="work-process__item work-process__item-two mb-30 text-center">
                        <div className="icon-wrapper d-flex">    
                            <div className="icon">
                                <i className="icon-research"></i>
                            </div>

                            <button className="theme-btn color-pd_black">Etape - 1</button>
                        </div>

                        <div className="text">
                            <h6 className="title color-pd_black mb-15 mb-sm-10 mb-xs-5"> Choisir sa zone et remplir le formulaire</h6>

                            <div className="description font-la">
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="work-process__item work-process__item-two mb-30 text-center">
                        <div className="icon-wrapper d-flex">    
                            <div className="icon">
                                <i className="icon-worker-1"></i>
                            </div>

                            <button className="theme-btn color-pd_black" >Etape - 2</button>
                        </div>

                        <div className="text">
                            <h6 className="title color-pd_black mb-15 mb-sm-10 mb-xs-5">Invitation au test de selection</h6>

                            <div className="description font-la">
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="work-process__item work-process__item-two mb-30 text-center">
                        <div className="icon-wrapper d-flex">    
                            <div className="icon">
                                <i className="icon-outline"></i>
                            </div>

                            <button className="theme-btn color-pd_black">Etape - 3</button>
                        </div>

                        <div className="text">
                            <h6 className="title color-pd_black mb-15 mb-sm-10 mb-xs-5">Invitation au paiement des frais</h6>

                            <div className="description font-la">
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="work-process__item work-process__item-two mb-30 text-center">
                        <div className="icon-wrapper d-flex">    
                            <div className="icon">
                                <i className="icon-target-2"></i>
                            </div>

                            <button className="theme-btn color-pd_black">Etape - 4</button>
                        </div>

                        <div className="text">
                            <h6 className="title color-pd_black mb-15 mb-sm-10 mb-xs-5"> Début des cours
</h6>

                            <div className="description font-la">
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
       
        </>

    );

}

