import Footer from "./Footer";
import Header1 from "./Header1";
import { usePDF } from 'react-to-pdf';
import { Link } from "react-router-dom";
export default function  Niveau2(){

    return(
        <>
        <Header1/>
        <Block1/>
        <Footer/>

             
        </>

    );

}

function Block1(){

  const { toPDF, targetRef } = usePDF({filename: '1.pdf'});

return (
<>


<section id="service-details" className="service-details section">

<div className="container">

  <div className="row gy-5">

    <div className="col-lg-4"  data-aos-delay="100">

    <div className="service-box">
        <h4></h4>
        <div className="services-list">
        <Link to={"/Niveau1"}>   <a href="#" ><i className="bi bi-arrow-right-circle"></i><span>NIVEAU I</span></a></Link>
        <Link to={"/Niveau2"}>  <a href="#" className="active"><i className="bi bi-arrow-right-circle"></i><span>NIVEAU II</span></a></Link>
        <Link to={"/Niveau3"}><a href="#" ><i className="bi bi-arrow-right-circle"></i><span>NIVEAU III</span></a></Link>
    
        </div>
      </div>
      <div class="service-box">
              <h4>Programme</h4>
              <div class="download-catalog">
                <a href="https://drive.google.com/file/d/1TgMT03_FYYHlbksHXD8xonBXor2cyF_B/view?usp=sharing"><i class="bi bi-filetype-pdf"></i><span> PROGRAMME PDF - 14-17 ans</span></a>
   
               
              

 
          
         
                
              </div>
            </div>
          


      <div className="help-box d-flex flex-column justify-content-center align-items-center">
        <i className="bi bi-headset help-icon"></i>
        <h4>Avez-vous des questions ?</h4>
        <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+41 76 765 18 92</span></p>
        <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+237 691291317</span></p>
        <p className="d-flex align-items-center mt-1 mb-0"><i className="bi bi-envelope me-2"></i> <a href="mailto:contact@example.com">infos@doctrinatech.org</a></p>
      </div>

    </div>

    <div className="col-lg-8 ps-lg-5"  data-aos-delay="200">
      <img src="assets/img/Niveau2.jpg" alt="" className="img-fluid services-img" />
      <h3>NIVEAU II -(14-17 ans).</h3>
      <p>
      Ce programme est spécialement conçu , afin de vous plonger dans l'univers fascinant des nouvelles technologies. Vous apprendrez à concevoir des applications en suivant une méthodologie simplifiée, tout en explorant des concepts plus avancés. À travers des activités stimulantes et des défis créatifs, vous aurez l'occasion de travailler sur des projets concrets qui éveilleront votre curiosité et renforceront vos compétences techniques. De plus, ce programme cultive un esprit entrepreneurial en vous encourageant à penser de manière critique et à résoudre des problèmes. Vous serez amenés à collaborer avec vos camarades, à partager vos idées et à développer des solutions. Prêt à relever le défi et à transformer vos idées en réalité
      </p>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 1 : Découverte des applications.</span></li>
        <p>
        Ce module attire  l'attention des jeunes sur le monde des applications informatiques. Avec une approche fun et créative, il vise à stimuler la curiosité tout en montrant comment utiliser la technologie de manière responsable. Ils auront l’opportunité d’explorer un univers rempli de possibilités tout en développant leurs compétences numériques de manière dynamique. Ce module donnera une compréhension pratique des applications et nourrira leurs passions pour la tech, tout en les préparant à un futur où maîtriser les outils numériques sera essentiel.

        </p>
      
      </ul>

      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 2 : Introduction à la conception et développement d’application.</span></li>
        <p>
        Ce module a pour mission d'ouvrir les portes d'un univers fascinant : celui des applications informatiques. Les jeunes seront invités à plonger dans la définition des applications, à explorer les multiples types qui existent et à découvrir les étapes clés pour créer leur propre application. Ce voyage leur permettra de poser les premières pierres de leur créativité et de mieux appréhender le monde numérique qui les entoure. En fin de parcours, ils seront équipés d'outils précieux pour imaginer et concrétiser leurs projets, tout en promouvant une utilisation réfléchie et responsable des technologies numériques.
        </p>
      
      </ul>

      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 3 : Les principes de programmation.</span></li>
        <p>
        Ce module vise à initier les enfants aux bases de la programmation avec Python de manière ludique. À travers des activités pratiques et des jeux interactifs, nous les aiderons à comprendre des concepts essentiels comme les boucles, les conditions et les variables. Nous favoriserons leur pensée logique et leur capacité à résoudre des problèmes tout en stimulant leur créativité grâce à des exercices simples en Python. Enfin, nous souhaitons éveiller leur intérêt pour la programmation en leur offrant une première expérience amusante qui démystifie ce domaine et les incite à explorer davantage.
        </p>
      
      </ul>
      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 4 : Concevoir des applications qui répondent aux besoins.</span></li>
        <p>
        Ce module a pour but d'offrir aux jeunes les connaissances essentielles et les compétences pratiques pour concevoir et prototyper des applications simples et intuitives. À travers des activités pratiques, ils découvriront les principes de la conception d'interface utilisateur, utiliseront des outils adaptés à leur âge et développeront leur créativité, leur logique et leurs compétences en communication. En atteignant ces objectifs, les jeunes établiront une base solide qui leur permettra de créer des interfaces attrayantes et conviviales, tout en nourrissant leur intérêt pour la conception et la technologie.

        </p>
      
      </ul>

      <ul>
        <li><i class="bi bi-archive-fill"></i> <span>Module 5 : Développement d'une application.</span></li>
        <p>
        Ce module introduit les jeunes au développement d'applications en leur enseignant les bases des applications Web, natives et hybrides, avec un accent sur Django. À travers des activités pratiques, ils apprendront les langages de programmation et les outils nécessaires. Ils découvriront les avantages et les inconvénients de chaque type d'application, apprendront les principes de conception d'interface utilisateur et développeront leur logique, leur créativité et leurs compétences en résolution de problèmes. En atteignant ces objectifs, ils seront capables de créer des applications fonctionnelles et attrayantes sur différentes plateformes, tout en suscitant leur intérêt pour la programmation et la technologie.
        </p>
      
      </ul>









    </div>

  </div>

</div>

</section>



</>





)


}