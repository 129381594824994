import { Link } from "react-router-dom";

export default function Atelier(){

    return(
        <>
        
        <section id="features" className="features section">


<div className="container section-title">
  <h2>ATELIERS</h2>
</div>

<div className="container">
  <div className="row justify-content-between">

    <div className="col-lg-5 d-flex align-items-center">

      <ul className="nav nav-tabs" data-aos-delay="100">
        <li className="nav-item">
          <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#features-tab-1">
            <i className="bi bi-binoculars"></i>
            <div>
              <h4 className="d-none d-lg-block">Initiation technologie informatique</h4>
              <p>
              Notre approche vise à rendre l'apprentissage ludique, interactif et éducatif, favorisant ainsi une meilleure compréhension des concepts informatiques.
              </p>
            </div>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-2">
            <i className="bi bi-box-seam"></i>
            <div>
              <h4 className="d-none d-lg-block">Journées portes ouvertes</h4>
              <p>
              Nous organisons des journées portes ouvertes virtuelles avec des professionnels aguerris dans le domaine de l'informatique mais aussi avec des personnes expérimentées qui  pourront motiver les apprenants en leur présentant des opportunités ou en abordant des thématiques spécifiques.
              </p>
            </div>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-3">
            <i className="bi bi-brightness-high"></i>
            <div>
              <h4 className="d-none d-lg-block">Assistance personnalisée</h4>
              <p>
              Nous mettons en place des mesures d’assistances personnalisées qui peuvent mieux cadrer avec les besoins individuels des apprenants en matière d'apprentissage informatique.
              </p>
            </div>
          </a>
        </li>
      </ul>

    </div>

    <div className="col-lg-6">

      <div className="tab-content"  data-aos-delay="200">

        <div className="tab-pane fade active show" id="features-tab-1">
          <img src="assets/img/tabs-1.jpg" alt="" className="img-fluid" />
        </div>

        <div className="tab-pane fade" id="features-tab-2">
          <img src="assets/img/tabs-2.jpg" alt="" className="img-fluid" />
        </div>

        <div className="tab-pane fade" id="features-tab-3">
          <img src="assets/img/tabs-3.jpg" alt="" className="img-fluid" />
        </div>

    </div>

  </div>
</div>
</div>

</section>
       
        </>

    );

}

