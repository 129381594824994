import { Link } from "react-router-dom";

export default function Adherer(){

    return(
        <>
        
        <section id="features-details" className="features-details section">

<div className="container">

  <div className="row gy-4 justify-content-between features-item" id="adhérer">

    <div className="col-lg-6"  data-aos-delay="100">
      <img src="assets/img/features-1.jpg" className="img-fluid" alt="" />
    </div>

    <div className="col-lg-5 d-flex align-items-center"  data-aos-delay="200">
      <div className="content">
        <h3>Découvrir, créer, innover, réussir.</h3>
        <p>
        Offrez à vos proches une longueur d'avance dans le monde moderne en les inscrivant à nos programmes axés sur la technologie, l'entrepreneuriat et d'autres secteurs passionnants. Inscrivez-les dès maintenant pour un avenir brillant.
        </p>
        <Link to={"/Pays"}> <a href="" className="btn more-btn">Adhérer</a> </Link>
      </div>
    </div>

  </div>

  <div id="soutenir" className="row gy-4 justify-content-between features-item">

    <div className="col-lg-5 d-flex align-items-center order-2 order-lg-1"  data-aos-delay="100">

      <div className="content">
        <h3>Soutenir un avenir technologique pour les jeunes</h3>
        <p>
        Soyez acteur de l'innovation technologique. Soutenez Doctrina Technologia avec un don pour encourager les jeunes à s'orienter vers la technologie et ainsi stimuler l'économie de demain
        </p>
        <ul>
          <li><i className="bi bi-easel flex-shrink-0"></i> Don en nature.</li>
          <li><i className="bi bi-patch-check flex-shrink-0"></i> Dons financiers.</li>
          <li><i className="bi bi-brightness-high flex-shrink-0"></i> Don de temps.</li>
        </ul>
        <p></p>
        <a href="#" className="btn more-btn">Soutenir</a>
      </div>

    </div>

    <div className="col-lg-6 order-1 order-lg-2"  data-aos-delay="200">
      <img src="assets/img/features-2.jpg" className="img-fluid" alt="" /> 
    </div>

  </div>

</div>

</section>
       
        </>

    );

}

