import { Link } from "react-router-dom";

export default function Nous(){

    return(
        <>
        
        <section id="about" className="about section">

<div className="container">

  <div className="row gy-4">

    <div className="col-lg-6 content"  data-aos-delay="100">
      <p className="who-we-are">QUI SOMMES NOUS</p>
      <h3>En tant qu'association, nous façonnons l'avenir en s’appuyant sur  une éducation numérique de qualité.</h3>
      <p className="fst-italic">
      Nous sommes une association basée en Suisse, qui s’est engagée à offrir aux apprenants des ressources éducatives pour encourager l'initiation aux nouvelles technologies de l’information, favoriser l'exploration d'autres domaines pour avoir une meilleure ouverture d’esprit, et inspirer la prochaine génération d'entrepreneurs.
      </p>
      <ul>
        <li><i className="bi bi-check-circle"></i> <span>Nous mettons à la disposition des apprenants, des programmes, des ateliers, des ressources de toutes natures afin de stimuler leur créativité et susciter l’envie d’exceller dans le numérique.</span></li>
        <li><i className="bi bi-check-circle"></i> <span>Nous développons des partenariats avec d’autres associations afin de rendre notre impact encore plus large.</span></li>
      </ul>
      <a href="#" className="read-more"><span>Savoir Plus</span><i className="bi bi-arrow-right"></i></a>
    </div>

    <div className="col-lg-6 about-images"  data-aos-delay="200">
      <div className="row gy-4">
        <div className="col-lg-6">
          <img src="assets/img/about-company-1.jpg" className="img-fluid" alt="" />
        </div>
        <div className="col-lg-6">
          <div className="row gy-4">
            <div className="col-lg-12">
              <img src="assets/img/about-company-2.jpg" className="img-fluid" alt="" />
            </div>
            <div className="col-lg-12">
              <img src="assets/img/about-company-3.jpg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>
</section>

        </>

    );

}

