import { Link } from "react-router-dom";

export default function Palier(){

    return(
        <>
            <section id="featured-services" className="featured-services section">

<div className="container">

  <div className="row gy-4">

    <div className="col-xl-4 col-lg-6"  data-aos-delay="100">
      <div className="service-item d-flex">
        <div className="icon flex-shrink-0"><i class="bi bi-book"></i></div>
        <div>
          <h4 className="title"><a href="#" className="stretched-link">FORMATION</a></h4>
          <p className="description">Se former est la clé de la réussite professionnelle et personnelle.</p>
        </div>
      </div>
    </div>


    <div className="col-xl-4 col-lg-6"  data-aos-delay="200">
      <div className="service-item d-flex">
        <div className="icon flex-shrink-0"><i class="bi bi-briefcase-fill"></i></div>
        <div>
          <h4 className="title"><a href="#" className="stretched-link">L' ORIENTATION</a></h4>
          <p className="description">L ' orientation est le premier pas vers la concrétisation de nos aspirations.</p>
        </div>
      </div>
    </div>

    <div className="col-xl-4 col-lg-6"  data-aos-delay="300">
      <div className="service-item d-flex">
        <div className="icon flex-shrink-0"><i class="bi bi-cast"></i></div>
        <div>
          <h4 className="title"><a href="#" className="stretched-link">L' ASSISTANCE</a></h4>
          <p className="description">L'assistance est un pilier essentiel pour inspirer la prochaine génération.</p>
        </div>
      </div>
    </div>

  </div>

</div>

</section>

       
        </>

    );

}

